<template>
  <div class="centered-wrapper">
    <div class="hall-of-fame-wrapper component-margin">
      <h1 v-if="title" class="headline text-center paddingBottom">{{ title }}</h1>
      <div class="filter">
        <b-form-select v-model="selected_year" :options="years" :title="selected_year"></b-form-select>
        <b-form-select v-model="selected_category" :options="categories" :title="selected_category"></b-form-select>
        <b-form-select v-model="selected_award" :options="awards" :title="selected_award"></b-form-select>
      </div>

      <ProjectsWall
          :collection="selected_collection" :key="selected_collection_key"/>

    </div>
  </div>
</template>

<script>
  import constants from "@/helpers/constants";
  import { BFormSelect } from 'bootstrap-vue';
  import ProjectsWall from "./ProjectsWall";

  export default {
    name: 'HallOfFame',
    components: {
      ProjectsWall,
      'b-form-select': BFormSelect
    },
    props: ['fromYear', 'toYear', 'title'],
    data(){
      return{
        context: 'bosw',
        collection: [],
        years: [{value: 'all', text: this.$t('filter.all_years')}],
        categories: [{value: 'all', text: this.$t('filter.all_categories')}],
        awards: [
          {
            value: 'all',
            text: this.$t('filter.all_awards')
          },
          {
            value: 'gold',
            text: this.$t('filter.gold')
          },
          {
            value: 'silber',
            text: this.$t('filter.silber')
          },
          {
            value: 'bronze',
            text: this.$t('filter.bronze')
          },
          {
            value: 'shortlist',
            text: this.$t('filter.shortlist')
          },
          {
            value: 'master',
            text: this.$t('filter.master')
          }],
        selected_year: 'all',
        selected_category: 'all',
        selected_award: 'gold',
        selected_collection_key: 0
      }
    },
    async mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }

      this.collection = await this.getCollection();
    },
    computed:{
      base_url(){
        if(this.context === 'bosw')
          return constants.BOSW_HOF_URL;
        else
          return constants.BOSA_HOF_URL;
      },
      selected_collection(){
        let collection = [];

        for(let item of this.collection){
          if((this.selected_year === item.year || this.selected_year === 'all')
            && (this.selected_category === item.category || this.selected_category === 'all')
            && (this.selected_award === item.award.toLowerCase() || this.selected_award === 'all')
            && !collection.some(entry => entry.project === item.project && entry.client === item.client && entry.contractor === item.contractor))
          {
            collection.push(item);
          }
        }

        return collection;
      }
    },
    watch:{
      selected_collection(){
        this.selected_collection_key++;
      }
    },
    methods:{
      async getCollection(){
        const start = this.fromYear ? parseInt(this.fromYear) : 2012;
        const end = this.toYear ? parseInt(this.toYear) : new Date().getFullYear();

        let collection = [];

        if(start && end){
          for(let year = start; year <= end; year++){
            try{
              const yearResponse = await fetch(`${this.base_url}hall-of-fame-${year}.json`, {mode: 'cors'});

              let yearEntries = await yearResponse.json();

              if(yearEntries){
                collection = collection.concat(yearEntries);

                for(let entry of yearEntries){
                  if(!this.years.map(option => option.value).includes(entry.year))
                    this.years.push({value:entry.year, text: entry.year});

                  if(!this.categories.map(option => option.value).includes(entry.category))
                    this.categories.push({value:entry.category, text: entry.category});
                }
              }
            }
            catch{
              console.log('error');
            }
          }
        }

        // Sort years in descending order
        this.years = this.years.sort((a, b) => {
          if (a.value > b.value) {
            return -1;
          }
          if (b.value > a.value) {
            return 1;
          }
          return 0;
        });

        this.selected_year = this.setSelectedYear(this.years);

        return collection;
      },
      setSelectedYear(yearOptions){
        const years = yearOptions.filter(option => option.value !== 'all').map(option => parseInt(option.value));

        const lastYear = years.length > 0 ? Math.max(...years) : '';

        return lastYear.toString() || 'all';
      }
    }
  };
</script>

<style scoped lang="scss">
  .accordion .hall-of-fame-wrapper.component-margin{
    margin-top:0 !important;
  }

  .hall-of-fame-wrapper{
    margin-bottom:$spacer*2;
  }

 .filter{
   max-width:100%;
   display:flex;
   flex-wrap:wrap;
   justify-content: center;

   select{
     margin: $spacer/4;
     max-width:350px;
     overflow:hidden;
     text-overflow: ellipsis;
     flex-shrink: 1;

     /deep/ option{
       font-weight:300;
     }
   }
 }

 @include media-breakpoint-down(md) {
   .filter{
     select{
       margin:$spacer/6;
       max-width:250px;
     }
   }
 }
</style>